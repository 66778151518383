import React, { useEffect, useState } from "react";
import SelectInputField from "../InputFields/SelectInputField/SelectInputField";
import TextInputField from "../InputFields/TextInputField/TextInputField";
//import "./AccountBasicInformationStyles/AccountBasicInformationStyles.scss";
import IndividualQualificationSection from "./AccountBasicInformation/AccountBasicInformation";
import TrustQualificationSection from "./../Qualification/TrustQualificationSection";
import EntityQualificationSection from "./../Qualification/EntityQualificationSection";
import MuiButton from "./../Buttons/MuiButton/MuiButton.js";
import { countryList } from "./../../utilities/lists/countryList.js";
import Countdown from "antd/lib/statistic/Countdown";
import { useNavigate } from "react-router-dom";

function AccountInfo({ user, setActiveSlide, setIsEnt }) {
  const navigate = useNavigate();
  const [qualifies, setQualifies] = useState(true);
  const [complete, setComplete] = useState(true);

  const [indComplete, setIndComplete] = useState(false);

  const [trustQualForm, setTrustQualForm] = useState({
    trustQualificationQ1: "",
    trustQualificationQ2: "",
    trustQualificationQ3: "",
    trustQualificationQ4: "",
  });

  const [entQualForm, setEntQualForm] = useState({
    trustQualificationQ1: "",
    trustQualificationQ2: "",
    trustQualificationQ3: "",
    trustQualificationQ4: "",
    trustQualificationQ5: "",
  });

  const submitEntityAccountBasicInformation = () => {
    if (accountType === "") {
      setAccountTypeError("This field is required.");
      return;
    }
    if (countryOfFormation === "" && accountType !== "IND")
      setCountryOfFormationError("This field is required.");
    if (legalAccountName === "" && accountType !== "IND")
      setLegalAccountNameError("This field is required.");
    if (
      (countryOfFormation === "" || legalAccountName === "") &&
      accountType !== "IND"
    )
      return;
    if (accountType === "TRUST" || accountType === "LT") {
      const trustComplete =
        trustQualForm.trustQualificationQ1 !== "" &&
        trustQualForm.trustQualificationQ2 !== "" &&
        trustQualForm.trustQualificationQ3 !== "" &&
        trustQualForm.trustQualificationQ4 !== "";
      // incomplete
      if (!trustComplete || countryOfFormation === "") {
        setComplete(false);
        return;
      }
      const trustQualifies =
        (trustQualForm.trustQualificationQ1 &&
          trustQualForm.trustQualificationQ2) ||
        (!trustQualForm.trustQualificationQ3 &&
          trustQualForm.trustQualificationQ4);
      if (trustQualifies)
        setActiveSlide((prevState) => prevState + increments(accountType));
      else setQualifies(false);
    } else if (accountType !== "" && accountType !== "IND") {
      const entComplete =
        entQualForm.trustQualificationQ1 !== "" &&
        entQualForm.trustQualificationQ2 !== "" &&
        entQualForm.trustQualificationQ3 !== "" &&
        entQualForm.trustQualificationQ4 !== "" &&
        entQualForm.trustQualificationQ5 !== "";

      if (!entComplete || countryOfFormation === "") {
        setComplete(false);
        return;
      }
      const entQualifies =
        entQualForm.trustQualificationQ1 === false &&
        ((entQualForm.trustQualificationQ2 &&
          entQualForm.trustQualificationQ3 === true) ||
          (entQualForm.trustQualificationQ2 &&
            entQualForm.trustQualificationQ4 === true) ||
          (entQualForm.trustQualificationQ3 === false &&
            entQualForm.trustQualificationQ4 === false &&
            entQualForm.trustQualificationQ5 === true) ||
          (entQualForm.trustQualificationQ3 === true &&
            entQualForm.trustQualificationQ4 === false &&
            entQualForm.trustQualificationQ5 == true));
      if (entQualifies)
        setActiveSlide((prevState) => prevState + increments(accountType));
      else setQualifies(false);
    } else if (accountType === "IND") {
      if (!indComplete) {
        setComplete(false)
        return; } 
      const indQualifies = qualifies;
      if (indQualifies) {
        navigate("/clients");
        setActiveSlide((prevState) => prevState + increments(accountType));
      }
    }
  };

  const accountTypeOptions = [
    { value: "IND", label: "Individual" },

    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
    { value: "LLC", label: "LLC" },
    { value: "C-CORP", label: "C Corporation" },
    { value: "S-CORP", label: "S Corporation" },
    { value: "LP", label: "Limited Partnership" },
    { value: "GP", label: "General Partnership" },
    { value: "FE", label: "Foreign Entity" },
  ];

  const [accountID, setAccountID] = useState("");
  const [countryOfFormation, setCountryOfFormation] = useState("");
  const [countryOfFormationError, setCountryOfFormationError] = useState("");

  const [accountType, setAccountType] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");
  const [showLegalAccountNameField, setShowLegalAccountNameField] =
    useState("");
  const [legalAccountName, setLegalAccountName] = useState("");
  const [legalAccountNameError, setLegalAccountNameError] = useState("");
  const [investorProfileComplete, setInvestorProfileComplete] = useState("");

  useEffect(() => {
    setComplete(true);
    setQualifies(true);
    setCountryOfFormationError("");
  }, [accountType]);

  useEffect(() => {
    setQualifies(true);
    setComplete(true);
    setCountryOfFormationError("");
  }, [trustQualForm]);

  useEffect(() => {
    setQualifies(true);
    setComplete(true);
    setCountryOfFormationError("");
  }, [entQualForm]);

  const mountedStyle = {
    opacity: 1,
    transition: "opacity 1.5s ease-out, transform 400ms ease-out",
  };
  const unmountedStyle = { opacity: 0, transition: "opacity 400ms ease-in" };

  const increments = (type) => {
    if (type === "IND") return 1;
    if (type === "LT" || type === "TRUST") return 2;
    setIsEnt(true);
    return 2;
  };

  return (
    <>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">Account Info</span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectInputField
            user={user}
            value={accountType}
            setValue={setAccountType}
            valueError={accountTypeError}
            setValueError={setAccountTypeError}
            selectOptions={accountTypeOptions}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"account-type-select"}
            label={"Account Type"}
          />
        </div>
        {true && (
          <div
            className="col pe-0 ps-2"
            style={
              accountType !== "IND" && accountType !== ""
                ? mountedStyle
                : unmountedStyle
            }
          >
            <TextInputField
              value={legalAccountName}
              setValue={setLegalAccountName}
              valueError={legalAccountNameError}
              setValueError={setLegalAccountNameError}
              label={"Legal Account Name"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"account-name-input"}
            />{" "}
          </div>
        )}
      </div>

      {accountType !== "IND" && accountType !== "" && (
        <div className="row g-0 w-50">
          {" "}
          <div
            className="col pe-0 mb-4"
            style={
              accountType !== "IND" && accountType !== ""
                ? mountedStyle
                : unmountedStyle
            }
          >
            <SelectInputField
              value={countryOfFormation}
              setValue={setCountryOfFormation}
              valueError={countryOfFormationError}
              setValueError={setCountryOfFormationError}
              label={"Country of Formation"}
              selectOptions={countryList}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"grantor-domicile-country-input"}
            />
          </div>
        </div>
      )}

      <div className="row g-0 w-100">
        {accountType !== "" &&
          (accountType === "IND" ? (
            <IndividualQualificationSection
              setComplete={setComplete}
              showQualSection={true}
              qualifies={qualifies}
              setQualifies={setQualifies}
              setIndComplete={setIndComplete}
            />
          ) : accountType === "TRUST" || accountType === "LT" ? (
            <TrustQualificationSection
              showQualSection={true}
              setActiveSlide={setActiveSlide}
              qualifies={qualifies}
              setQualifies={setQualifies}
              setQualForm={setTrustQualForm}
              qualForm={trustQualForm}
            />
          ) : (
            <EntityQualificationSection
              showQualSection={true}
              setActiveSlide={setActiveSlide}
              qualifies={qualifies}
              setQualifies={setQualifies}
              qualForm={entQualForm}
              setQualForm={setEntQualForm}
            />
          ))}
      </div>
      <div className="d-flex">
        {qualifies && (
          <MuiButton
            buttonText={"Next Step"}
            onClick={submitEntityAccountBasicInformation}
            size={"large"}
          />
        )}
      </div>
      {!qualifies ? (
        <div className="row g-0 w-100 mb-4">
          <div className="col-10">
            <div className="row g-0 w-100">
              <div className="col">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                  alt="error"
                  width="15"
                  height="15"
                />
                <span className="ms-3 trust-qualification-error-text">
                  Sorry, your account doesn't qualify.
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!complete ? (
        <div className="row g-0 w-100 mb-4">
          <div className="col-10">
            <div className="row g-0 w-100">
              <div className="col">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                  alt="error"
                  width="15"
                  height="15"
                />
                <span className="ms-3 trust-qualification-error-text">
                  All questions are required.
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default AccountInfo;
