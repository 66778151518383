import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../Contexts/UserContext.js";
import "./Styles/Styles.scss";
import { Link, useLocation } from "react-router-dom";
import EllipseDropdown from "../EllipseDropdown/EllipseDropdown.js";
import Media from "react-media";
import { useMediaQuery } from "react-responsive";
import Spinner1 from "../Spinners/Spinner1.js";

function Header({ activePage, setActivePage }) {
  const { user, setUser } = useContext(UserContext);
  const [investSelected, setInvestSelected] = useState(false);
  const [portfolioSelected, setPortfolioSelected] = useState(false);
  const [clientsSelected, setClientsSelected] = useState(false);
  const [supportSelected, setSupportSelected] = useState(false);
  const [capitalCallsSelected, setCapitalCallsSelected] = useState(false);
  const [documentsSelected, setDocumentsSelected] = useState(false);
  const [tasksSelected, setTasksSelected] = useState(false);
  const [teamSelected, setTeamSelected] = useState(false);

  // const [manageSelected, setManageSelected] = useState(false);

  const [messagesSelected, setMessagesSelected] = useState(false);
  const [learnSelected, setLearnSelected] = useState(false);
  const [faqSelected, setFaqSelected] = useState(false);
  const [investmentAccountSelected, setInvestmentAccountSelected] =
    useState(false);
  const [text, setText] = useState("");
  const [defaultKey, setDefaultKey] = useState([]);
  const [firmLogoLoading, setFirmLogoLoading] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const location = window.location.pathname;

  useEffect(() => {
    var items = [];
    items.push(text);
    setDefaultKey(items);
  }, [text]);

  useEffect(() => {
    setActivePage(location.slice(1))
  }, [location]);

  useEffect(() => {
    if (activePage === "invest") {
      setPortfolioSelected(false);
      setClientsSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestSelected(true);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "clients") {
      setInvestSelected(false);
      setClientsSelected(true);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setPortfolioSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setTasksSelected(false);
      setDocumentsSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "portfolio") {
      setInvestSelected(false);
      setClientsSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setPortfolioSelected(true);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "messages") {
      setInvestSelected(false);
      setClientsSelected(false);
      setLearnSelected(false);
      setPortfolioSelected(false);
      setFaqSelected(false);
      setMessagesSelected(true);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "learn") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setFaqSelected(false);
      setLearnSelected(true);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "faq") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(true);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);

      setDefaultKey([]);
    } else if (activePage === "investment-accounts") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(true);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);
      setDefaultKey([]);
    } else if (activePage === "support") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(true);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
      // setManageSelected(false);
      setDefaultKey([]);
    } else if (activePage === "") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
    } else if (activePage === "capital-calls") {
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(true);
      setDocumentsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
    } else if (activePage === "documents") {
      setDocumentsSelected(true);
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setTasksSelected(false);
      setTeamSelected(false);
    } else if (activePage === "tasks") {
      setDocumentsSelected(false);
      setInvestSelected(false);
      setClientsSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setTasksSelected(true);
      setTeamSelected(false);
    } else if (activePage === "team") {
      setTeamSelected(true);
      setPortfolioSelected(false);
      setClientsSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
    }
    else if (activePage === "investments") {
      setTeamSelected(false);
      setPortfolioSelected(false);
      setClientsSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setInvestSelected(false);
      setInvestmentAccountSelected(false);
      setSupportSelected(false);
      setCapitalCallsSelected(false);
      setDocumentsSelected(false);
      setTasksSelected(false);
    }
  }, [activePage]);

  const firmLogoOnLoad = () => {
    setFirmLogoLoading(false);
    setInvestSelected(false);
    setClientsSelected(false);
    setPortfolioSelected(false);
    setMessagesSelected(false);
    setLearnSelected(false);
    setFaqSelected(false);
    setInvestmentAccountSelected(false);
    setSupportSelected(false);
    setCapitalCallsSelected(false);
  };

  return location.substring(0, 6) === "/login" ||
    location.substring(0, 7) === "/signup" ||
    location.substring(0, 14) == "/feeder-signup" ||
    location.substring(0, 8) === "/subdocs" ? null : (
    <React.Fragment>
      <nav
        //
        className="navbar navbar-expand-lg navbar-light bg-light pt-3 pb-3 header-style"
        style={{ backgroundColor: "#ffffff !important" }}
      >
        <div className="container-fluid">
          <div className="navbar-brand">
            <div className="container-fluid">
              {user !== null &&
              user !== undefined &&
              user.firmInfo.logoLink !== "" ? (
                <div className="d-flex align-items-center">
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={user.firmInfo.logoLink}
                      height="36"
                      style={{
                        display: firmLogoLoading ? "none" : "inline-block",
                        height: "36px",
                      }}
                      onLoad={firmLogoOnLoad}
                    />
                    <div
                      className="animated-slow"
                      height="36"
                      style={{
                        height: "36px",
                        width: "100px",
                        borderRadius: "10px",
                        display: firmLogoLoading ? "inline-block" : "none",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
                  alt="logo"
                  height="36"
                  loading="lazy"
                ></img>
              )}
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ width: "50%" }}>
              <div className="row g-0 w-100">
                <div className="col-12">
                  <div className="row g-0 w-100">
                    {user &&
                      user.isAuthenticated &&
                      user.userRole !== "Vendor" && (
                        <div className="col-md col-12">
                          <li className="nav-item">
                            <div className="row g-0 h-100 w-100 justify-content-md-center justify-content-start">
                              <div className="align-self-center justify-self-center">
                                <Link
                                  to="/invest"
                                  onClick={() => setActivePage("invest")}
                                >
                                  <div className="nav-link text-center w-100">
                                    <span
                                      className={
                                        investSelected
                                          ? "nav-text-selected"
                                          : "nav-text"
                                      }
                                    >
                                      Invest
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div
                                  className={
                                    investSelected
                                      ? "title-bar-active align-self-end justify-self-center"
                                      : "title-bar-inactive align-self-end justify-self-center"
                                  }
                                  style={{ width: "56px" }}
                                ></div>
                              )}
                            </div>
                          </li>
                        </div>
                      )}
                    {user &&
                      user.isAuthenticated &&
                      user.userRole === "Self-Serve" && (
                        <div className="col-md col-12">
                          <li className="nav-item">
                            <div className="row g-0 w-100 h-100 justify-content-center">
                              <div className="align-self-center justify-self-center">
                                <Link
                                  to="/tasks"
                                  onClick={() => setActivePage("tasks")}
                                >
                                  <div className="nav-link text-center w-100">
                                    <span
                                      className={
                                        tasksSelected
                                          ? "nav-text-selected"
                                          : "nav-text"
                                      }
                                    >
                                      Tasks
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div
                                  className={
                                    tasksSelected
                                      ? "title-bar-active align-self-end justify-self-center"
                                      : "title-bar-inactive align-self-end justify-self-center"
                                  }
                                  style={{ width: "80px" }}
                                ></div>
                              )}
                            </div>
                          </li>
                        </div>
                      )}

                    {
                      <div className="col-md col-12">
                        <li className="nav-item">
                          <div className="row g-0 h-100 w-100 justify-content-center">
                            <div className="align-self-center justify-self-center">
                              <Link
                                to={
                                  user &&
                                  user.isAuthenticated &&
                                  user.userRole !== "Self-Serve"
                                    ? "/clients"
                                    : "/account"
                                }
                                onClick={() => setActivePage("clients")}
                              >
                                <div className="nav-link text-center w-100">
                                  <span
                                    className={
                                      clientsSelected
                                        ? "nav-text-selected"
                                        : "nav-text"
                                    }
                                  >
                                    {user &&
                                    user.isAuthenticated &&
                                    user.userRole !== "Self-Serve"
                                      ? "Clients"
                                      : "Account"}
                                  </span>
                                </div>
                              </Link>
                            </div>
                            {!isMobile && (
                              <div
                                className={
                                  clientsSelected
                                    ? "title-bar-active align-self-end justify-self-center"
                                    : "title-bar-inactive align-self-end justify-self-center"
                                }
                                style={{ width: "70px" }}
                              ></div>
                            )}
                          </div>
                        </li>
                      </div>
                    }
                    {/*user &&
                      user.isAuthenticated &&
                      ["Master", "Ops", "Advisor", "Co-Advisor"].includes(user.userRole) && (
                        <div className="col-md col-12">
                          <li className="nav-item">
                            <div className="row g-0 w-100 h-100 justify-content-center">
                              <div className="align-self-center justify-self-center">
                                <Link
                                  to="/investments"
                                  onClick={() => setActivePage("investments")}
                                >
                                  <div className="nav-link text-center w-100">
                                    <span
                                      className={
                                        (activePage === "investments")
                                          ? "nav-text-selected"
                                          : "nav-text"
                                      }
                                    >
                                      Investments
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div
                                  className={
                                    (activePage === "investments")
                                      ? "title-bar-active align-self-end justify-self-center"
                                      : "title-bar-inactive align-self-end justify-self-center"
                                  }
                                  style={{ width: "80px" }}
                                ></div>
                              )}
                            </div>
                          </li>
                        </div>
                      )*/}
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link
                            to="/investment-accounts"
                            onClick={() => setActivePage("investment-accounts")}
                          >
                            <div className="nav-link text-center w-100 text-nowrap">
                              <span
                                className={
                                  investmentAccountSelected
                                    ? "nav-text-selected"
                                    : "nav-text"
                                }
                              >
                                Investment Accounts
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                investmentAccountSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "174px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link
                              to="/documents"
                              onClick={() => setActivePage("documents")}
                            >
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    documentsSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Documents
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                documentsSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "80px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    {user &&
                      user.isAuthenticated &&
                      user.userRole !== "Vendor" && (
                        <div className="col-md col-12">
                          <li className="nav-item">
                            <div className="row g-0 w-100 h-100 justify-content-center">
                              <div className="align-self-center justify-self-center">
                                <Link
                                  to="/portfolio"
                                  onClick={() => setActivePage("portfolio")}
                                >
                                  <div className="nav-link text-center w-100">
                                    <span
                                      className={
                                        portfolioSelected
                                          ? "nav-text-selected"
                                          : "nav-text"
                                      }
                                    >
                                      Portfolio
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div
                                  className={
                                    portfolioSelected
                                      ? "title-bar-active align-self-end justify-self-center"
                                      : "title-bar-inactive align-self-end justify-self-center"
                                  }
                                  style={{ width: "80px" }}
                                ></div>
                              )}
                            </div>
                          </li>
                        </div>
                      )}
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link
                              to="/manage-users"
                              onClick={() => setActivePage("manage")}
                            >
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    manageSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Manage Users
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                manageSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "80px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link
                              to="/capital-calls"
                              onClick={() => setActivePage("capital-calls")}
                            >
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    capitalCallsSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Capital Calls
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                capitalCallsSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "80px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    {user &&
                      user.isAuthenticated &&
                      (user.userRole === "Advisor" ||
                        user.userRole === "Co-Advisor") && (
                        <div className="col-md col-12">
                          <li className="nav-item">
                            <div className="row g-0 w-100 h-100 justify-content-center">
                              <div className="align-self-center justify-self-center">
                                <Link
                                  to="/team"
                                  onClick={() => setActivePage("team")}
                                >
                                  <div className="nav-link text-center w-100">
                                    <span
                                      className={
                                        teamSelected
                                          ? "nav-text-selected"
                                          : "nav-text"
                                      }
                                    >
                                      Team
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {!isMobile && (
                                <div
                                  className={
                                    teamSelected
                                      ? "title-bar-active align-self-end justify-self-center"
                                      : "title-bar-inactive align-self-end justify-self-center"
                                  }
                                  style={{ width: "56px" }}
                                ></div>
                              )}
                            </div>
                          </li>
                        </div>
                      )}

                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link
                              to="/learn"
                              onClick={() => setActivePage("learn")}
                            >
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    learnSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Learn
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                learnSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "46px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link to="/faq" onClick={() => setActivePage("faq")}>
                            <div className="nav-link text-center w-100">
                              <span
                                className={
                                  faqSelected ? "nav-text-selected" : "nav-text"
                                }
                              >
                                FAQs
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                faqSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "43px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                  </div>
                </div>
              </div>
            </ul>
            <Media queries={{ large: { minWidth: 992 } }}>
              {(matches) =>
                matches.large ? (
                  <div className="ms-lg-auto me-lg-4 ms-auto me-auto text-lg-start text-center">
                    <span className="body-text-md-bold">
                      {user
                        ? user.userProfileData.firstName +
                          " " +
                          user.userProfileData.lastName
                        : ""}
                    </span>
                  </div>
                ) : null
              }
            </Media>

            <div className="row mt-lg-0">
              <div className="ms-auto me-auto justify-self-center">
                <div class="nav-item dropdown d-flex justify-content-center">
                  <EllipseDropdown
                    text={text}
                    setText={setText}
                    defaultKey={defaultKey}
                    setActivePage={setActivePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
export default Header;
