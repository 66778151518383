import React from "react";
import "./Styles/Styles.scss";
import JointAccountHolderInformation from "./JointAccountHolderInformation/JointAccountHolderInformation";
import TrusteeInformation from "./TrusteeInformation/TrusteeInformation";
import BeneficialOwnerInformation from "./BeneficialOwnerInformation.js/BeneficialOwnerInformation";

function RelatedPartyInformation({
  accountType,
  jointHolderFirstName,
  setJointHolderFirstName,
  jointHolderFirstNameError,
  setJointHolderFirstNameError,
  jointHolderLastName,
  setJointHolderLastName,
  jointHolderLastNameError,
  setJointHolderLastNameError,
  jointHolderEmail,
  setJointHolderEmail,
  jointHolderEmailError,
  setJointHolderEmailError,
  jointHolderPhoneNumber,
  setJointHolderPhoneNumber,
  jointHolderPhoneNumberError,
  setJointHolderPhoneNumberError,
  jointHolderTaxIDNumber,
  setJointHolderTaxIDNumber,
  jointHolderTaxIDNumberError,
  setJointHolderTaxIDNumberError,
  jointHolderDOB,
  setJointHolderDOB,
  jointHolderDOBError,
  setJointHolderDOBError,
  jointHolderIsUSCitizen,
  setJointHolderIsUSCitizen,
  jointHolderIsUSCitizenError,
  setJointHolderIsUSCitizenError,
  jointHolderAddressLine1,
  setJointHolderAddressLine1,
  jointHolderAddressLine1Error,
  setJointHolderAddressLine1Error,
  jointHolderAddressLine2,
  setJointHolderAddressLine2,
  jointHolderAddressLine2Error,
  setJointHolderAddressLine2Error,
  jointHolderCity,
  setJointHolderCity,
  jointHolderCityError,
  setJointHolderCityError,
  jointHolderState,
  setJointHolderState,
  jointHolderStateError,
  setJointHolderStateError,
  jointHolderPostalCode,
  setJointHolderPostalCode,
  jointHolderPostalCodeError,
  setJointHolderPostalCodeError,
  hasTrustees,
  setHasTrustees,
  relatedPartyInfo,
  setRelatedPartyInfo,
  activeTab,
  setActiveTab,
  setHideButton,
  hideAlterButtons,
  addNewTab,
  handleRemoveFields,
  disabled,
  hasBeneficialOwners,
  setHasBeneficialOwners,
  beneficialOwnerInfo,
  setBeneficialOwnerInfo,
  beneficialOwnerActiveTab,
  setBeneficialOwnerActiveTab,
  handleBeneficialOwnerRemoveField,
  setHideBeneficialOwnerButton,
  hideAlterBeneficialOwnerButtons,
  addNewBeneficialOwnerTab,
  hasTrustManagementCompany = false,
}) {
  return (
    <React.Fragment>
      {accountType === "JOINT" && (
        <div className="row g-0 w-100">
          <JointAccountHolderInformation
            jointHolderFirstName={jointHolderFirstName}
            setJointHolderFirstName={setJointHolderFirstName}
            jointHolderFirstNameError={jointHolderFirstNameError}
            setJointHolderFirstNameError={setJointHolderFirstNameError}
            jointHolderLastName={jointHolderLastName}
            setJointHolderLastName={setJointHolderLastName}
            jointHolderLastNameError={jointHolderLastNameError}
            setJointHolderLastNameError={setJointHolderLastNameError}
            jointHolderEmail={jointHolderEmail}
            setJointHolderEmail={setJointHolderEmail}
            jointHolderEmailError={jointHolderEmailError}
            setJointHolderEmailError={setJointHolderEmailError}
            jointHolderPhoneNumber={jointHolderPhoneNumber}
            setJointHolderPhoneNumber={setJointHolderPhoneNumber}
            jointHolderPhoneNumberError={jointHolderPhoneNumberError}
            setJointHolderPhoneNumberError={setJointHolderPhoneNumberError}
            jointHolderTaxIDNumber={jointHolderTaxIDNumber}
            setJointHolderTaxIDNumber={setJointHolderTaxIDNumber}
            jointHolderTaxIDNumberError={jointHolderTaxIDNumberError}
            setJointHolderTaxIDNumberError={setJointHolderTaxIDNumberError}
            jointHolderDOB={jointHolderDOB}
            setJointHolderDOB={setJointHolderDOB}
            jointHolderDOBError={jointHolderDOBError}
            jointHolderIsUSCitizen={jointHolderIsUSCitizen}
            setJointHolderIsUSCitizen={setJointHolderIsUSCitizen}
            jointHolderIsUSCitizenError={jointHolderIsUSCitizenError}
            setJointHolderIsUSCitizenError={setJointHolderIsUSCitizenError}
            setJointHolderDOBError={setJointHolderDOBError}
            jointHolderAddressLine1={jointHolderAddressLine1}
            setJointHolderAddressLine1={setJointHolderAddressLine1}
            jointHolderAddressLine1Error={jointHolderAddressLine1Error}
            setJointHolderAddressLine1Error={setJointHolderAddressLine1Error}
            jointHolderAddressLine2={jointHolderAddressLine2}
            setJointHolderAddressLine2={setJointHolderAddressLine2}
            jointHolderAddressLine2Error={jointHolderAddressLine2Error}
            setJointHolderAddressLine2Error={setJointHolderAddressLine2Error}
            jointHolderCity={jointHolderCity}
            setJointHolderCity={setJointHolderCity}
            jointHolderCityError={jointHolderCityError}
            setJointHolderCityError={setJointHolderCityError}
            jointHolderState={jointHolderState}
            setJointHolderState={setJointHolderState}
            jointHolderStateError={jointHolderStateError}
            setJointHolderStateError={setJointHolderStateError}
            jointHolderPostalCode={jointHolderPostalCode}
            setJointHolderPostalCode={setJointHolderPostalCode}
            jointHolderPostalCodeError={jointHolderPostalCodeError}
            setJointHolderPostalCodeError={setJointHolderPostalCodeError}
          />
        </div>
      )}
      {(accountType === "LT" || accountType === "TRUST") && (
        <TrusteeInformation
          hasTrustees={hasTrustees}
          setHasTrustees={setHasTrustees}
          relatedPartyInfo={relatedPartyInfo}
          setRelatedPartyInfo={setRelatedPartyInfo}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          addNewTab={addNewTab}
          handleRemoveFields={handleRemoveFields}
          disabled={disabled}
          relatedPartyType={"TRT"}
          accountType={accountType}
        />
      )}
      {(["LLC", "S-CORP", "C-CORP", "GP", "LP", "FE"].includes(accountType) ||
        accountType === "LT" ||
        (accountType === "TRUST")) && (
        <BeneficialOwnerInformation
          hasBeneficialOwners={hasBeneficialOwners}
          setHasBeneficialOwners={setHasBeneficialOwners}
          beneficialOwnerInfo={beneficialOwnerInfo}
          setBeneficialOwnerInfo={setBeneficialOwnerInfo}
          beneficialOwnerActiveTab={beneficialOwnerActiveTab}
          setBeneficialOwnerActiveTab={setBeneficialOwnerActiveTab}
          handleRemoveFields={handleBeneficialOwnerRemoveField}
          disabled={disabled}
          setHideButton={setHideBeneficialOwnerButton}
          hideAlterButtons={hideAlterBeneficialOwnerButtons}
          addNewTab={addNewBeneficialOwnerTab}
          relatedPartyType={"BO"}
          accountType={accountType}
        />
      )}
    </React.Fragment>
  );
}
export default RelatedPartyInformation;
