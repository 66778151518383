import React, { useState, useEffect, useContext} from "react";
import { UserContext } from "../../../Contexts/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import "./Styles/Styles.scss";
import Tabs from "../../../Components/Tabs/Tabs";
import Tab from "../../../Components/Tabs/Tab/Tab";
import NavLinks from "../../NavLinks/NavLinks";
import {
  GetFundSubscriptionInfo,
  GetInvestorFundInvestment,
} from "../../../Adapters/FundActions";
import SpecifyClientInvestments from "./SpecifyClientInvestments/SpecifyClientInvestments";
import SpecifyInvestment from "./SpecifyInvestment/SpecifyInvestment";
import InvestmentDetails from "./InvestmentDetails/InvestmentDetails";
import ConfirmInvestments from "./NewConfirmInvestments";

const InvestFund = () => {
  const { user } = useContext(UserContext);
  const { id, client_id} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [specifyClientInvestments, setSpecifyClientInvestments] =
    useState(true);
  const [selectClient, setSelectClient] = useState(true);
  const [specifyInvestment, setSpecifyInvestment] = useState(false);
  const [investmentConfirmation, setInvestmentConfirmation] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [refresh, setRefresh] = useState(0);
  const { fundSubscriptionInfo } = GetFundSubscriptionInfo(id);
  const { investorFundInvestmentInfo } = GetInvestorFundInvestment(id, refresh);

  useEffect(() => {
    if (selectedTab === 0) {
      setSelectClient(true);
      setSpecifyInvestment(false);
      setInvestmentConfirmation(false);
    } else if (selectedTab === 1) {
      setSpecifyInvestment(true);
      setSelectClient(false);
      setInvestmentConfirmation(false);
    } else if (selectedTab === 2) {
      setInvestmentConfirmation(true);
      setSelectClient(false);
      setSpecifyInvestment(false);
    }
  }, [selectedTab]);

  return (
    <div className="container-xxl mt-2 pt-2 pb-3">
      <NavLinks />
      <div className="row g-0 w-100 mb-3">
        { (user.userRole !== 'Self-Serve' && id !== "syon") ? <Tabs selectedTab={selectedTab}>
          <Tab
            key={0}
            title="Specify Investments"
            selected={specifyClientInvestments}
            setSelected={setSpecifyClientInvestments}
            disableClick={true}
            fullWidth
          >
            <SpecifyClientInvestments
              id={id}
              setSelectedClient={specifyClientInvestments}
              setSelectedTab={setSpecifyClientInvestments}
            />
          </Tab>
          <Tab
            key={2}
            title=""
            selected={investmentConfirmation}
            setSelected={setInvestmentConfirmation}
            disableClick={true}
          ></Tab>
        </Tabs> : <ConfirmInvestments user={user} client_id={client_id} /> }
      </div>
    </div>
  );
};
export default InvestFund;
