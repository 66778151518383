import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import FilterComponent from "./FilterComponent";


const SelectableTable: React.FC<any> = ({ data, fund, setSelClient }) => {
  let history = useNavigate();

  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const handleRowClick = (id: string) => {

    setSelectedRow((id === selectedRow) ? null : id);

    (id !== selectedRow) && setSelClient(data.find((row: any) => id === row.client_id));
    (id == selectedRow) && setSelClient(null);
  };

  const [filter, setFilter] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const onSearch = (term: any) => {
    setFilter(term);
  }
  const onStatusChange = (term: any) => {
    console.log(term);
    setStatus(term);
  }

  const statusFormatter = (row: any) => {
    return (row.status[fund] === undefined) ? "Uninvested" : row.status[fund]
  }

  const clientsToShow = data.filter((row: any) => 
    ((row.name.startsWith(filter) || row.email.startsWith(filter)) && ( statusFormatter(row) === status || (status === ""))));
  
  return (
    <div className="tw-space-y-4">
      <FilterComponent onSearch={onSearch} onStatusChange={onStatusChange} />
      <div>
        {clientsToShow.length !==0 ? <table className="tw-table-auto tw-border-collapse tw-border tw-border-gray-200 tw-w-full">
          <thead>
            <tr>
              <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                Client Name
              </th>
              <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                Email
              </th>
              <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                Status
              </th>
              <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                KYC
              </th>
              <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {clientsToShow.map((row: any) => (
              <tr
                key={row.client_id}
                onClick={() => handleRowClick(row.client_id)}
                className={`tw-cursor-pointer ${selectedRow === row.client_id ? 'tw-bg-blue-100' : 'tw-bg-white'
                  } hover:tw-bg-blue-50`}
              >
                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                  {row.name}
                </td>
                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                  {row.email}
                </td>
                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                  {statusFormatter(row)}
                </td>
                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                  {row.kyc_outstanding === true ? <button
                    className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                    onClick={() => { history(`/clients/kyc/${row.client_id}`); }}
                  >
                    Resolve
                  </button> : <> No action required</>}
                </td>
                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                  <button
                    className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                    onClick={() => { history(`/clients/${row.client_id}`)  }}
                  >
                    Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>: <>No clients found.</>} </div></div>
  );
};

export default SelectableTable;