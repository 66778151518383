import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import FilterComponent from "./FilterComponent";
import { getInvestments } from "./../../../APIs/WealthManagementAPI"

const InvestmentTable: React.FC<any> = ({ fund }) => {
    let history = useNavigate();

    const [selectedRow, setSelectedRow] = useState<string | null>(null);
    const [data, setData] = useState<any[]>([]);
    const handleRowClick = (id: string) => {
    };

    useEffect(() => {
        async function getInvestmentData() {
            const inv = await getInvestments();
            setData(inv.data.data);
        } getInvestmentData();
    }, []);

    return (
            <div className="container ps-3 pe-3"><div className="row g-0 w-100">
                <div className="tw-p-4 tw-space-y-6">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Investments</h1>
                    <div>
                        <div className="tw-space-y-4 tw-overflow-x-auto">
                            <div>
                                { data.length !== 0 ? <table className="tw-table-auto tw-border-collapse tw-border tw-border-gray-200 tw-w-full">
                                    <thead>
                                        <tr>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Account Name
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Email
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Account Type
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Fund
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Amount
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Date
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                Status
                                            </th>
                                            <th className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-left tw-text-sm tw-font-medium">
                                                KYC
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length !== 0 && data.map((row: any) => (
                                            <tr
                                                key={row.client_id}
                                                onClick={() => handleRowClick(row.client_id)}
                                                className={`tw-cursor-pointer ${selectedRow === row.client_id ? 'tw-bg-blue-100' : 'tw-bg-white'
                                                    } hover:tw-bg-blue-50`}
                                            >
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.entity_type ? row.entity_name : row.investor_name}
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.investor_email}
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.entity_type ? row.entity_type : "IND"}
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.fund__name}
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.investment_amount.toLocaleString()}
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.investment_date.slice(0, 10)}
                                                </td>

                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    <a href={`/fund/syon/confirm-investment/client/${row.client_id}`}>{row.status}</a>
                                                </td>
                                                <td className="tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm">
                                                    {row.kyc_outstanding === true ? <button
                                                        className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                                                        onClick={() => { history(`/clients/kyc/${row.client_id}`); }}
                                                    >
                                                        Resolve
                                                    </button> : <> No action required</>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> : <>No investments found.</>}</div></div></div></div></div></div>
    );
};

export default InvestmentTable;